body, button {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: #424242;
    background-color: #e8e8e8;
}

header {
    width: 100%;
    height: 100px;
    background-color: #fff;
    -webkit-box-shadow: 0px 0px 10px #888;
    -moz-box-shadow: 0px 0px 10px #888;
    box-shadow: 0px 0px 10px #888;
    z-index: 1000;
    position: fixed;
    top: 0px;
    padding: 5px 25px 0px;
}

hr {
    background-color: #ccc;
    height: 1px;
    border: none;
    margin: 50px 0px 50px 0px;
}

button {
    font-size: 14pt;
    color: #fff;
    background-color: #00b300;
    width: 50%;
    height: 60px;
    border: 1px solid #81FF81;
    border-radius: 5px;
    margin-top: 10px;
    padding: 5px;
}

button:hover {
    background-color: #009800;
    cursor: pointer;
}

label {
    letter-spacing: 3px;
    font-weight: 500;
}

img {
    border-radius: 5px;
}

#recipes-list-container {
    text-align: center;
    margin: 10px;
}

#recipe-card, .form-container {
    width: 80%;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.087);
    margin: auto;
    padding: 25px;
}

#recipe-name {
    color: #00b300;
}

#recipe-name:hover {
    color: #009800;
}

#no-photo-container {
    font-size: 18pt;
    font-weight: 700;
    color: #9a9a9a;
    background-color: #dddddd;
    height: 225px;
    border-radius: 5px;
    text-align: center;
}

#camera {
    color: #9a9a9a;
    padding-top: 25px;
}

.outer-content-container {
    width: 80%;
    min-height: 100vh;
    background-color: #fff;
    margin: auto;
    padding: 140px 25px 25px;
}

.form-container div {
    margin: 0px 0px 10px;
}

.search {
    margin: 0px 25px 25px;
    padding: 0px 25px 0px;
}

.recipe-tile {
    background-color: #fff;
    width: 275px;
    text-align: center;
    border: 1px solid #ddd;
    border-radius: 5px;
    cursor: pointer;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.087);
    margin-bottom: 25px;
    padding: 10px;
}

.padding-tile {
    width: 275px;
    padding-left: 10px;
    padding-right: 10px;
    visibility: hidden;
}

.directions {
    white-space: pre-wrap;
    overflow-wrap: break-word;
}

.float-left {
    float: left;
}

.flex-between {
    display: flex;
    justify-content: space-between;
}

.flex-around {
    display: flex;
    justify-content: space-around;
}

.flex-left-align-center {
    display: flex;
    justify-content: left;
    align-items: center;
}

.flex-wrap {
    flex-wrap: wrap;
}

.Toastify__toast--success {
    background-color: #00b300;
    border-radius: 5px;
}

.Toastify__toast--error {
    background-color: #ff0000;
    border-radius: 5px;
}

.Toastify__close-button {
    height: 100%;
    background-color: transparent;
    color: #fff;
    text-align: right;
    margin: 0px;
}

.Toastify__close-button:hover, .Toastify__close-button:focus {
    background-color: transparent;
    color: #ddd;
}