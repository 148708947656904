#outer-login-container {
    width: 60%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
}

#login-container {
    background-color: #fff;
    width: 80%;
    text-align: center;
    border: 2px solid #00b300;
    border-radius: 5px;
    padding: 25px;
}

#login-container div {
    margin: 0px 0px 10px;
}

.disclaimer {
    font-size: small;
    color: tomato;
    background-color: #eee;
    padding: 5px;
}

@media only screen and (min-width: 1064px) {
    #outer-content-container {
        width: 925px;
    }
}

@media only screen and (max-width: 695px) {
    #outer-content-container {
        width: 90%;
    }
}

@media only screen and (max-width: 411px) {
    #outer-login-container {
        width: 100%;
    }
    #login-container {
        width: 90%;
        height: 90%;
    }
}