a {
    color: #00b300;
    text-decoration: none;
}

a:hover {
    color: #009800;
}

nav {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
  
menu {
  min-width: 160px;
  background-color: #fff;
  display: none;
  position: absolute;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 900;
}

menu a {
  color: #fff;
  background-color: #00b300;
  display: block;
  padding: 10px 15px;
}

menu div a:hover {
  color: #fff;
  background-color: #009800;
}

.user-icon, .hamburger-icon {
  color: #00b300;
  overflow: hidden;
}

.user-icon:hover, .hamburger-icon:hover {
  color: #009800;
}

.hamburger-icon {
  display: none;
}

.user-icon:hover menu, .hamburger-icon:hover menu {
  display: block;
}

#hamburger-nav {
  width: 80%;
  margin: auto;
}

@media (max-width: 800px) {
  .hamburger-icon {
    display: block;
  }
  nav {
    display: none;
  }
}